import { Card, FlexBox, Typography } from "@vp/swan";
import { useLogger, useSwanStyleKeys, useUserContext } from "@vp/ubik-context";

interface Payment {
  text: string;
}

export interface Props {
  savedPayments: Payment[];
}

export const Fragment = ({ savedPayments }: Props) => {
  const logger = useLogger();
  const { locale } = useUserContext();
  logger.log("Rendering fragment for a locale", locale);

  useSwanStyleKeys(["core"]);

  return (
    <FlexBox>
      <Card style={{ width: "100%" }}>
        <Typography textAllCaps fontWeight="bold" mb={1} mr={1}>
          Saved Payments
        </Typography>
        {savedPayments.map((payment) => (
          <Typography key={payment.text} mb={1}>
            {payment.text}
          </Typography>
        ))}
      </Card>
    </FlexBox>
  );
};
